import React from "react";

const cleanBookName = (bookName: string) => bookName.toLowerCase().replace(" ", "").replace("-", "");

export const BookImage = ({ book, ...rest }: { book: { name: string } } & any) => {
  return (
    <img
      data-src={`https://static.rotoql.com/sportsbooks/rectangular/v2/${cleanBookName(book.name)}.png`} // prettier-ignore
      alt={book.name}
      {...rest}
      className={`lazyload ${rest.className}`}
    />
  );
};

export const BookIcon = ({ book, ...rest }: { book: { name: string } } & any) => {
  return (
    <img
      data-src={`https://static.rotoql.com/sportsbooks/square/${cleanBookName(book.name)}.png`} // prettier-ignore
      alt={book.name}
      {...rest}
      className={`lazyload ${rest.className}`}
    />
  );
};
