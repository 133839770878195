import React from "react";
import Icon from "@mdi/react";
import { mdiStar, mdiStarHalf } from "@mdi/js";
import { COMP_YELLOW, COMP_NEUTRAL } from "../colors";

/**
 * Render the star rating by the rating value. Handles negative values correctly
 *
 * @param displayRating The max rating that we want to display
 */
export const renderRatingByValue = (
  rating: number,
  showText?: boolean,
  className?: string,
  showRating: boolean = true
) => {
  const absRating: number = Math.abs(rating);
  const half = absRating % 1 > 0;
  const intRating = Math.trunc(absRating);

  let starList: any = [];
  for (let i = 0; i < 5; i++) {
    if (i === intRating && half) {
      starList.push(<Icon key={i} path={mdiStarHalf} size="24px" color={COMP_YELLOW} />);
    } else {
      starList.push(
        <Icon
          key={i}
          path={mdiStar}
          size="24px"
          color={intRating !== 0 && i < intRating && showRating ? COMP_YELLOW : COMP_NEUTRAL}
        />
      );
    }
  }
  return (
    <div className={className ? className : "d-inline-block"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "24px",
        }}
      >
        {starList}
      </div>
      {showText ? (
        <p
          style={{
            fontWeight: 400,
            fontSize: 13,
            textAlign: "right",
            color: "#8a8a8a",
          }}
        >
          {intRating}
        </p>
      ) : null}
    </div>
  );
};

/**
 * Render the rating by passing the trend object
 *
 * @param trend The trend value that we want to render
 * @param showText Do you want to show text next to trend value being displayed?
 * @param className A className to put on the best bet rating container
 * @param showRating Boolean that determine if we want to show yellow star
 */
export const renderRating = (trend: any, showText?: boolean, className?: string, showRating?: boolean) => {
  let maxRating = trend && trend.displayRating ? trend.displayRating : 0;
  return renderRatingByValue(maxRating, showText, className, showRating);
};
