import React, { useState, useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";

function CountAnimation(props: {
  countTo: number; // Number to count to
  animationDuration?: number; // How long (in ms) animation should last
}) {
  const [didAnimate, setDidAnimate] = useState(false);
  const { ref, inView } = useInView();
  const { countTo, animationDuration = 2000 } = props;

  if (!countTo) return <span>0</span>; // eslint-disable-line

  const frameFactor = countTo / animationDuration;
  const animationReq = useRef<any>();
  const startTime = useRef<number>();
  const [count, setCount] = useState<number>(0);

  function animateCount(now: any) {
    if (startTime.current !== undefined) {
      const elapsed = now - startTime.current;
      setCount((prevCount: number) => Math.min(prevCount + elapsed * frameFactor, countTo));
    }

    startTime.current = now;
    animationReq.current = requestAnimationFrame(animateCount);
    if (count >= countTo) cancelAnimationFrame(animationReq.current); // eslint-disable-line
  }

  useEffect(() => {
    if (!inView || didAnimate) {
      return;
    }

    setDidAnimate(true);
    animationReq.current = requestAnimationFrame(animateCount);
    return () => cancelAnimationFrame(animationReq.current);
  }, [inView]);

  return <span ref={ref}>{Math.round(count)}</span>;
}

export default React.memo(CountAnimation);
