import React, { useState, useContext, useRef, useEffect } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { RECOMMENDED_BETS_QUERY } from "src/gql/queries";
import styles from "./styles.scss";
import _, { sortBy } from "lodash";
import moment from "moment-timezone";
import { WEB_SPORTS_LIST, RECOMMENDED_BETS_SELECTOR_CONFIG, STATE_MAP, DEFAULT_TIMEZONE } from "src/constants";
import { useCarousel } from "../Carousel";
import TeamLogo from "../Team/TeamLogo";
import { renderRating } from "src/services/ValueDataService";
import Dropdown, { DropdownItem } from "src/components/Dropdown";
import AnalyticsService from "src/services/AnalyticsService";
import { SubscriptionContext } from "src/services/SubscriptionService";
import Tooltip from "src/components/Tooltip";
import CountAnimation from "src/components/CountAnimation";
import FocusedTextLink from "../FocusedTextLink";
import { BookImage } from "../BookImage";
import { MetaBetLink } from "src/services/OfferService";
import { Icon } from "@mdi/react";
import { mdiLock } from "@mdi/js";
import Link from "next/link";
import { currentSport } from "src/services/ApolloService";

// This is a hack due to rec bets tech debt
const getRecBetBestLineText = (recBet: any, hasAccess: boolean) => {
  const bestLine = _.cloneDeep(recBet.bestLine);
  const isTotal = Boolean(bestLine.homeText.match(/[OU]/));
  const isMoneyOrSpread = !isTotal;
  const faveSide = bestLine.awayValue < bestLine.homeValue ? "away" : "home";
  const bestBetSide = hasAccess ? recBet.line.preferredTeam : faveSide;

  // Best bet side or home if total
  let bestLineText = bestLine[`${bestBetSide}Text`];

  // Add team abbreviation
  if (isMoneyOrSpread) {
    bestLineText = `${recBet.homeTeam.preferredAbbreviation} ${bestLineText}`;
  }

  // Hide O/U pick
  if (!hasAccess && isTotal) {
    bestLineText = bestLineText.replace(/[OU]/, "O/U");
  }

  return bestLineText;
};

// NOTE: key is DB league name. text is display name
const leagueOptions: DropdownItem[] = [{ key: null as any, name: "All Sports" }, ...RECOMMENDED_BETS_SELECTOR_CONFIG];

const getLeagueFromName = (name: string) => WEB_SPORTS_LIST.find((x) => x.key === name);

const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "usd",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);

const RecommendedBets = (props: any) => {
  const ctx = useContext(SubscriptionContext);
  const currentSportObj = useReactiveVar(currentSport);
  const leagueInDropdown = Boolean(leagueOptions.find((x) => x.key === currentSportObj?.key));
  const [league, setLeague] = useState<string | null>(
    (!props.isHomepage && leagueInDropdown && currentSportObj?.key) || null
  );
  const { data, loading } = useQuery(RECOMMENDED_BETS_QUERY, {
    ssr: props.ssr || false,
    variables: { league },
  });
  const containerRef = useRef<any>(null);

  useEffect(() => {
    if (!props.isHomepage && leagueInDropdown) {
      setLeague(currentSportObj?.key ?? null);
    } else {
      setLeague(null);
    }
  }, [currentSportObj?.key]);

  // Container query allows placement in sidebar
  const onResize = ({ width }: any) => {
    if (width > 575) {
      containerRef.current?.style.setProperty("--mobile", "invalidate-property");
    } else {
      containerRef.current?.style.setProperty("--mobile", "");
    }
  };

  // Add helpful sort properties
  let bets =
    data?.recommendedBets.map((item: any) => {
      const now = moment();
      const gameStart = moment.utc(item.startDate).tz(DEFAULT_TIMEZONE);
      const isGameToday = !gameStart.isAfter(now, "date");
      const isDailySport = !getLeagueFromName(item.league.name)?.hasConfiguredSchedule;
      return { ...item, isGameToday, isDailySport };
    }) || [];

  bets = sortBy(bets, [
    ({ eventState, isDailySport, isGameToday }) => {
      // Finalized events last
      if (eventState === "FINAL") {
        return 3;
      }
      // Non-daily sports 1st
      if (!isDailySport) {
        return 1;
      }
      // Daily sports today also 1st
      if (isDailySport && isGameToday) {
        return 1;
      }
      // Daily sports not today 2nd
      return 2;
    },
  ]);

  // Filter out 5th or greater item if daily sport and game is not today
  // NOTE: Might want to remove this code for sake of showing as many trends as possible
  bets = bets.filter(({ isDailySport, isGameToday }: any, index: number) => {
    if (index >= 4 && isDailySport && !isGameToday) {
      return false;
    }
    return true;
  });

  if (!ctx.hasSubscriptionAccess(bets?.[0]?.league?.name) && Boolean(bets?.[0]?.line) === true) {
    const idx = bets.findIndex((bet: any) => !bet?.line);
    if (idx > -1) {
      bets.unshift(bets.splice(idx, 1)[0]);
    }
  }

  const betComponents = loading
    ? [1, 2, 3, 4, 5, 6].map((i) => <RecommendedBetPlaceholder dark={props.dark} key={i} />)
    : bets.map((bet: any) => <RecommendedBet item={bet} />);

  const { Carousel, CarouselNav } = useCarousel(betComponents, {
    onResize,
    contentHash: String(loading) + bets.map((x: any) => String(Boolean(x?.line))).join(""),
  });

  return (
    <div className={`recommended-bets ${props.className || ""}`} ref={containerRef}>
      {/* Header (title and dropdown) */}
      <div className="header">
        <span className="header-title">
          <h2>Bets based on Hottest Trends</h2>
          <Tooltip title="Bets based on Hottest Trends">
            <p className="tooltip-text">
              Recommended bets that also have performed well historically. The list updates in real time. To meet the
              criteria, an upcoming game must display:
            </p>
            <p className="tooltip-text">1. 3 to 5 star best bet rating to indicate strong value right now</p>
            <p className="tooltip-text">2. Trends must have a 55% profitable win rate over at least 10 games</p>
          </Tooltip>
        </span>
        <Dropdown
          className="sport-dropdown"
          items={leagueOptions}
          selectedIndex={leagueOptions.findIndex((o) => o.key === league)}
          onSelectItem={(o) => setLeague(o.key as string | null)}
        />
        <CarouselNav />
      </div>
      <Carousel />
      {!loading && !bets.length ? (
        <div className="no-bets">
          <div>
            There are currently no recommended bets for this sport. View the 'All' section or another sport to see more
            bets.
          </div>
        </div>
      ) : null}
      <style jsx>{styles}</style>
    </div>
  );
};

export default RecommendedBets;

const RecommendedBetPlaceholder = ({ dark }: { dark: boolean }) => {
  const cardStyle = dark ? { background: "#FFFFFF88", opacity: 0.1 } : {};
  return (
    <div className="card" style={{ height: 266, ...cardStyle }}>
      <div className="win-percent placeholder" style={{ width: 110 }}>
        &nbsp;
      </div>
      <div className="game">
        <div className="game-row">
          <div className="game-logo placeholder" />
          <div style={{ width: 6 }} />
          <div className="game-abbrev placeholder">&nbsp;</div>
          <div style={{ width: 12 }} />
          <div className="game-line-text placeholder" style={{ width: 40 }}>
            &nbsp;
          </div>
          <div className="game-star-holder placeholder" style={{ width: 100 }}>
            &nbsp;
          </div>
        </div>
        <div style={{ height: 2 }} />
        <div className="game-row">
          <div className="game-logo placeholder" />
          <div style={{ width: 6 }} />
          <div className="game-abbrev placeholder">&nbsp;</div>
          <div style={{ width: 12 }} />
          <div className="game-line-text placeholder" style={{ width: 40 }}>
            &nbsp;
          </div>
          <div className="game-star-holder placeholder" style={{ width: 100 }}>
            &nbsp;
          </div>
        </div>
      </div>
      <div style={{ height: 12 }} />
      <div className="title placeholder" style={{ height: 24 }} />
      <div style={{ height: 2 }} />
      <div className="description placeholder" style={{ height: 40 }} />
      <div className="cta-button placeholder">&nbsp;</div>
    </div>
  );
};

// Recommended Bet Card
const RecommendedBet = ({ item }: { item: any }) => {
  const ctx = useContext(SubscriptionContext);

  const hasAccess = Boolean(item.line); // to test use index % 2 === 0, get index from props
  const homePreferred = hasAccess && item.line.preferredTeam === "home";
  const homeAbbrev = item.homeTeam.preferredAbbreviation;
  const awayAbbrev = item.awayTeam.preferredAbbreviation;

  const onSeeFullAnalysis = (bet: any) => () => {
    AnalyticsService.track("Open Game", {
      platform: "web",
      page: window.location.href,
      component: "RecommendedBets",
      league: bet.league.name,
    });
  };

  const onGetTonightsBet = (bet: any) => () => {
    ctx.redirectToPricing(
      {
        platform: "web",
        page: window.location.href,
        component: "RecommendedBets",
        source: "Open IAP Modal",
        sport: bet.league.name,
        awayTeam: item.awayTeam.fullName,
        homeTeam: item.homeTeam.fullName,
        gameId: item.id,
      },
      "Open IAP Modal",
      0,
      [bet.league.name]
    );
  };

  const makeTeamLogo = (teamAbbreviation: any) => (
    <TeamLogo
      containerClassName="game-logo"
      leagueName={getLeagueFromName(item.league.name)?.imagePath ?? ""}
      teamAbbreviation={teamAbbreviation}
      size={21}
    />
  );

  // Currently the backend returns a string for winPercent (e.g. "99%")
  const winPercent = Number(item.winPercent.split("%")[0]);
  const profit = ` - ${formatCurrency(item.totalReturn)} Profit`;

  const winMarkup = (
    <div className="win-percent">
      <CountAnimation countTo={winPercent} animationDuration={2000} />% Win Rate
      {profit}
    </div>
  );

  const gameMarkup = hasAccess ? (
    <div className="game" aria-label="Team Detail Info">
      {/* Home Team */}
      <div className="game-row">
        {makeTeamLogo(homeAbbrev)}
        <div className="game-abbrev" style={homePreferred ? { fontWeight: "bold" } : {}}>
          {homeAbbrev}
        </div>
        <div className="game-arrow-holder">{homePreferred && <div className="green-arrow" />}</div>
        <div className="game-line-text" style={homePreferred ? { fontWeight: "bold" } : {}}>
          {item.line.homeText}
        </div>
        <div className="game-star-holder">
          {homePreferred && (
            <>
              {renderRating({ displayRating: item.line.stars }, false)}
              {item.line.betType.includes("1h") && (
                <div className="game-star-1h" style={{ bottom: -18 }}>
                  1st Half
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/* Away Team */}
      <div className="game-row">
        {makeTeamLogo(awayAbbrev)}
        <div className="game-abbrev" style={!homePreferred ? { fontWeight: "bold" } : {}}>
          {awayAbbrev}
        </div>
        <div className="game-arrow-holder">{!homePreferred && <div className="green-arrow" />}</div>
        <div className="game-line-text" style={!homePreferred ? { fontWeight: "bold" } : {}}>
          {item.line.awayText}
        </div>
        <div className="game-star-holder">
          {!homePreferred && (
            <>
              {renderRating({ displayRating: item.line.stars }, false)}
              {item.line.betType.includes("1h") && (
                <div className="game-star-1h" style={{ top: -18 }}>
                  1st Half
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  ) : null;

  const dividerMarkup = <div className="divider" />;

  const spacerMarkup = <div style={{ flexGrow: 1 }} />;

  const paddingMarkup = <div style={{ height: 8 }} />;

  const titleDescriptionMarkup = (
    <React.Fragment>
      <div className="title">{item.title}</div>
      <div className="description">{item.description}</div>
    </React.Fragment>
  );

  const dayString = item.gameIsToday ? "Tonight's" : "This Week's";
  // prettier-ignore
  const ctaMarkup = hasAccess ? (
    <Link
      href={`/${getLeagueFromName(item.league.name)?.url}/game-predictions/${item.slugId}`}
      legacyBehavior>
      <a className="cta-button" onClick={onSeeFullAnalysis(item)}>
        See Full Analysis
      </a>
    </Link>
  ) : (
    <FocusedTextLink onClick={onGetTonightsBet(item)} className="cta-button">
      <Icon
        style={{ marginRight: "5px" }}
        path={mdiLock}
        size="14px"
        color="#fff"
      />
      Get {dayString} Bet for {awayAbbrev}-{homeAbbrev}
    </FocusedTextLink>
  );

  const { bestLine, sportsdataGameId } = item;

  const bestLineMarkup = STATE_MAP[ctx.userLocale]?.legal ? (
    <section className="best-line">
      <h3 className="best-line-title">Bet the Best Line Available With:</h3>
      <div className="best-line-book">
        <BookImage book={bestLine.book} style={{ height: 24 }} className="toggler book-image" />
        <div className="bet-now">
          <p className="bet-text">{getRecBetBestLineText(item, hasAccess)}</p>
          <MetaBetLink
            className="bet-button"
            href={bestLine.book.prismicOffers?.[0]?.web_link}
            target="_blank"
            rel="noopener"
            onClick={() =>
              AnalyticsService.track("Recommended Bet Now", {
                url: window.location.href,
                sport: item.league.name,
                book: bestLine.book.name,
                state: ctx.userLocale,
                component: "RecommendedBet",
                lineType: item.line?.betType ?? null,
              })
            }
            bookName={bestLine.book.name}
            sportsdataGameId={sportsdataGameId}
          >
            Bet Now
          </MetaBetLink>
        </div>
      </div>
    </section>
  ) : null;

  return (
    <div className="card" aria-label="Individual Bet">
      {winMarkup}
      {gameMarkup}
      {hasAccess && dividerMarkup}
      {titleDescriptionMarkup}
      {!hasAccess && spacerMarkup}
      {!hasAccess && dividerMarkup}
      {hasAccess && paddingMarkup}
      {ctaMarkup}
      {bestLineMarkup}
    </div>
  );
};
